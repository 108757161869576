const importers = {
    drawer: [
        ({path}: any) => import(`./drawers/${path}Drawer`),
        ['gameMenu']
    ],
    idp_form: [
        ({path}: any) => import(`./idp-forms/${path}IdpForm`),
        ['email', 'helloasso']
    ],
    idp_selector: [
        ({path}: any) => import(`./idp-selectors/${path}IdpSelector`),
        ['email', 'helloasso']
    ],
    screen: [
        ({path}: any) => import(`./screens/${path}Screen`),
        [
            'login',

            'invitation',

            'public/public_partner_register_donee_idps',
            'public/public_partner_register_game_idps',
            'public/public_callback',
            'public/public_partner_register_game',
            'public/public_partner_register_sponsor',
            'public/public_partner_register_donee',

            'private_sale',
            'private_book',
            'private_sponsoring',
            'private_grouping',
            'private_gimport',
            'private_ridp',
            'private_changerequest',

            'private/registration/private_registration',

            'publate/game/publate_game_autologin',
            'publate/game/publate_game_layout',

            'private/game/private_game_layout',
            'private/game/private_game_manage',
            'private/game/private_game_home',
            'private/game/private_game_tickets',
            'private/game/private_game_tickets_draw_tickets',
            'private/game/private_game_tickets_tickets',
            'private/game/private_game_ticket',
            'private/game/private_game_tickets_books',
            'private/game/private_game_tickets_books_details',
            'private/game/private_game_tickets_booksets',
            'private/game/private_game_tickets_booksets_details',
            'private/game/private_game_bunches',
            'private/game/private_game_bunches_edit',
            'private/game/private_game_bunches_public_page_display_mode',
            'private/game/private_game_bunches_collect_mode',
            'private/game/private_game_fundraising',
            'private/game/private_game_fundraising_integrations_payment',
            'private/game/private_game_fundraising_integrations_payment_details',
            'private/game/private_game_fundraising_payments',
            'private/game/private_game_fundraising_payments_details',
            'private/game/private_game_sales',
            'private/game/private_game_sales_sales',
            'private/game/private_game_sales_sales_details',
            'private/game/private_game_sales_preorders',
            'private/game/private_game_sales_preorders_details',
            'private/game/private_game_sales_promises',
            'private/game/private_game_sales_promises_details',
            'private/game/private_game_sales_bookpayments',
            'private/game/private_game_sales_bookpayments_details',
            'private/game/private_game_data',
            'private/game/private_game_help',
            'private/game/private_game_help_contact_us',
            'private/game/private_game_game',
            'private/game/private_game_links',
            'private/game/private_game_game_integrations_payment',
            'private/game/private_game_game_integrations_payment_details',
            'private/game/private_game_game_edit_images',
            'private/game/private_game_game_edit_notifications',
            'private/game/private_game_game_edit_ticket_price',
            'private/game/private_game_tickets_import',
            'private/game/private_game_changerequest',
            'private/game/private_game_billing',
            'private/game/private_game_billing_plan',
            'private/game/private_game_billing_manage',
            'private/game/private_game_godfather',
            'private/game/private_game_godfathers',
            'private/game/private_game_godfathers_godfathers',
            'private/game/private_game_godfathers_godfathers_rankings',
            'private/game/private_game_godfathers_godfathers_rankings_by_period_and_dimension',
            'private/game/private_game_sellers',
            'private/game/private_game_seller',
            'private/game/private_game_sellers_sellers',
            'private/game/private_game_sellers_sellers_sellers',
            'private/game/private_game_sellers_sellers_rankings',
            'private/game/private_game_sellers_sellers_rankings_by_dimension',
            'private/game/private_game_sellergroup',
            'private/game/private_game_sellers_sellergroups',
            'private/game/private_game_sellers_sellergroups_sellergroups',
            'private/game/private_game_sellers_sellergroups_rankings',
            'private/game/private_game_sellers_sellergroups_rankings_by_dimension',
            'private/game/private_game_account',
            'private/game/private_game_account_organization',
            'private/game/private_game_account_organization_edit',
            'private/game/private_game_account_organization_edit_urls',
            'private/game/private_game_account_organization_edit_type',
            'private/game/private_game_account_organization_edit_logo_image',
            'private/game/private_game_account_organization_edit_banner_image',
            'private/game/private_game_account_user_edit',
            'private/game/private_game_account_user',
            'private/game/private_game_sponsorings',
            'private/game/private_game_sponsorings_edit',
            'private/game/private_game_sponsorings_public_page_display_mode',
            'private/game/private_game_blocks',
            'private/game/private_game_blocks_block',

            'private/organization/private_organization_layout',
            'private/organization/private_organization_home',

            'publate/organization/publate_organization_layout',
            'publate/organization/publate_organization_module',

            'public/public_partner_idp_email',
        ],
    ],
    game_step: [
        ({path}: any) => import(`./organisms/game/${path}GameStep`),
        [
            'summary', 'infos', 'game', 'gameDate', 'gameTicketPrice', 'country', 'locale', 'gameTickets', 'supportedLocales',
            'notifications', 'presentations', 'logoImage', 'bannerImage', 'summarySettings', 'summaryImages', 'bunches', 'sponsorings',
            'summaryColors', 'theme', 'summaryLanguages', 'summaryTickets', 'summaryTexts', 'integrations', 'summaryVideos', 'videos',
            'gimagesTop', 'gimagesCenter', 'gimagesBottom', 'gameSubject', 'saleData', 'options', 'gvideos', 'summaryPublicInformations', 'publicInformations', 'otherImages', 'summaryOtherImages',
            'terms', 'texture', 'effect', 'dfonts', 'gameCustomPacks', 'gameCustomBonuses',
        ],
    ],
    registration_step: [
        ({path}: any) => import(`./organisms/registration/${path}RegistrationStep`),
        [
            'organizationType', 'organization', 'summary', 'game', 'gameTheme', 'gameBunches',
            'country', 'url', 'gameSubject', 'gameTicketPrice', 'gameTickets', 'owner', 'gameDate', 'gameOptions',
            'saleData', 'locale', 'createNow', 'created', 'supportedLocales', 'gimages', 'presentations', 'disclaimer', 'banner', 'logo',
            'warning', 'gameType', 'welcome', 'optins', 'failed', 'startDelay',
        ],
    ],
    registration_short_step: [
        ({path}: any) => import(`./organisms/registration/${path}RegistrationStep`),
        [
            'organizationType', 'organization', 'summary', 'game', 'gameTheme', 'gameBunches',
            'country', 'url', 'gameSubject', 'gameTicketPrice', 'gameTickets', 'owner', 'gameDate', 'gameOptions',
            'saleData', 'locale', 'createNow', 'created', 'supportedLocales', 'gimages', 'presentations', 'disclaimer', 'banner', 'logo',
            'warning', 'gameType', 'welcome', 'optins', 'failed', 'startDelay',
        ],
    ],
    gimport_step: [
        ({path}: any) => import(`./organisms/gimport/${path}GimportStep`),
        [
            'ticketsMode', 'ticketsText', 'ticketsUpload', 'summary', 'startNow', 'completed'
        ],
    ],
    changerequest_step: [
        ({path}: any) => import(`./organisms/changerequest/${path}ChangerequestStep`),
        [
            'summary', 'published', 'publish'
        ],
    ],
    ticket_step: [
        ({path}: any) => import(`./organisms/ticket/${path}TicketStep`),
        [
            'summary', 'edit',
        ],
    ],
    godfather_step: [
        ({path}: any) => import(`./organisms/godfather/${path}GodfatherStep`),
        [
            'summary', 'edit',
        ],
    ],
    seller_step: [
        ({path}: any) => import(`./organisms/seller/${path}SellerStep`),
        [
            'summary', 'edit',
        ],
    ],
    sellergroup_step: [
        ({path}: any) => import(`./organisms/sellergroup/${path}SellergroupStep`),
        [
            'summary', 'edit',
        ],
    ],
};

export default importers;
